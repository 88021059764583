import * as React from "react";
import { ImageHelper, IMediaModel, RouteHelper, UrlQuery } from "@nf/common";
import { NextRouter } from "next/router";
import ImageNext from "next/image";

export type TItemPlaceholderSSR = {
  media?: IMediaModel;
  router: NextRouter;
};

import styles from "./ItemPlaceholderSSR.module.scss";

export const ItemPlaceholderSSR = ({ media, router }: TItemPlaceholderSSR) => {
  const detailsUrlObject = RouteHelper.goToDetails(
    media,
    router.query as UrlQuery
  );
  const image = ImageHelper.getPosterImageUrl(media?.Images);
  const description = media?.Description || media?.ShortDescription;
  const category = media?.Categories?.[0]?.CategoryName;
  return (
    <div className={styles.container}>
      <a
        className={styles.text}
        href={`/${router.query.country}/${router.query.language}${detailsUrlObject.pathname}`}
      >
        {media?.Title && <h2 className={styles.text}>{media?.Title}</h2>}
        {/* eslint-disable-next-line @next/next/no-img-element */}
        {image && typeof image === "string" && (
          <ImageNext
            src={image}
            alt={media?.Title}
            quality={90}
            width={510}
            height={286}
            loading="lazy"
            priority={false}
          />
        )}
        {description && <p className={styles.text}>{description}</p>}
        {media?.Year && <p className={styles.text}>{media?.Year}</p>}
        {category && <p className={styles.text}>{category}</p>}
      </a>
    </div>
  );
};

export default ItemPlaceholderSSR;
