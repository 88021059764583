import React from "react";
import { isServerSideRender } from "@nf/helpers";
import { CellType, IMediaListModel, IMediaModel } from "@nf/common";
import { useRouter } from "next/router";
import { ItemPlaceholderSSR } from "./ItemPlaceholderSSR";

export type TListComponentHorizontalSSR = {
  mediaListSSR?: IMediaListModel;
  mediaList?: IMediaModel[];
  cellType?: CellType;
};

export const ListComponentHorizontalSSR = ({
  mediaListSSR,
  mediaList,
  cellType,
}: TListComponentHorizontalSSR): JSX.Element | null => {
  const router = useRouter();

  return mediaListSSR || mediaList ? (
    <div>
      {cellType === CellType.Promo &&
        mediaList?.map(
          (media: IMediaModel, index) =>
            isServerSideRender && (
              <ItemPlaceholderSSR
                media={media}
                router={router}
                key={`${media?.Id}-${index}`}
              />
            )
        )}
      {mediaListSSR &&
        mediaListSSR?.Entities?.map(
          (media: IMediaModel, index) =>
            isServerSideRender && (
              <ItemPlaceholderSSR
                media={media}
                router={router}
                key={`${media.Id}-${index}`}
              />
            )
        )}
    </div>
  ) : null;
};

export default ListComponentHorizontalSSR;
